/**
 * 存储全局socket对象
 */

export default {
    ws: null,
    modeMap :null,
    meid:'',
    homeid:'',
    baseUrl:'',
    websessionon:'',
    initdata:false,
    socketopen:false,
    weixinconfig:false,
    stream:null,
    messages:new Array(),
    setStream: function (stream) {
      this.stream = stream;
    },
    getStream: function () {
        return this.stream;
    },
    setWebsocketOpenn: function (socketopen) {
      this.socketopen = socketopen;
    },
    getWebsocketOpenn: function () {
      return this.socketopen;
    },
    setWs: function (newWs) {
        this.ws = newWs;
    },
    setInitData: function (initdata) {
        this.initdata = initdata;
    },
    getInitData: function () {
        return this.initdata;
    },
    setMeId: function (meid) {
        this.meid = meid;
    },
    getMeId: function () {
        return this.meid;
    },
    setWeixinConfig: function (config) {
      this.weixinconfig = config;
    },
    getWeixinConfig: function () {
        return this.weixinconfig;
    },
    setHomeId: function (homeid) {
        this.homeid = homeid;
    },

    getHomeId: function () {
        return this.homeid;
    },
    setBaseUrl: function (baseUrl) {
      this.baseUrl = baseUrl;
   },
   getWebSessionon: function () {
      return this.websessionon;
   },
   setWebSessionon: function (websessionon) {
    this.websessionon = websessionon;
 },
 getBaseUrl: function () {
    return this.baseUrl;
 },
 sendToServer(message) {
        let data = JSON.stringify(message);
        //console.log("goble sendToServer------------------",data);
        if(this.ws!= null && this.ws.readyState === 1){
            console.log("sendToServer===============",data);
            this.ws.send(data);
        }else{
           // console.log("sendToServer   error message =",message); 
            this.messages.push(message);
        }
        
    },
    getMessage: function (msg) {
        // console.log("goble getMessage-----------------",msg);
        
        if(this.modeMap!= null){
            for(let callback of this.modeMap.values()){
                   
                     if(typeof callback == "function"){
                       callback(msg);
                     } 
           }
        }
        if(msg!= null && msg.data!= null){
            if(msg.data.sessionId!= this.homeId){
              return ;
            }
        }
        switch(msg.eventName) {
            case "_login":
            this.handleLoginMsg(msg.data);
            break;
            case "_logout":
            this.handleLogoutMsg(msg.data);
            break;
            case "_publish":
            this.handlePublishMsg(msg.data);
            break;	
            case "_ping":
              console.log("goble Got default message", msg);
            break;									
            default:
            //console.log("goble Got default message", msg);
            break;
        }

    },
    handleLoginMsg(data) {

        var datas = localStorage.getItem('datas')
        if(datas!=null && datas!= undefined && datas.length>0){
          var objectdatas = JSON.parse(datas);

          objectdatas.forEach(item =>{
          
            if(item.serno==data.from){
                item.state="online";
            }
        
           }); 
           localStorage.setItem('datas',JSON.stringify(objectdatas));

        }

      
      },
     handleLogoutMsg(data) {

        var datas = localStorage.getItem('datas')
        if(datas!=null && datas!= undefined && datas.length>0){
          var objectdatas = JSON.parse(datas);

          objectdatas.forEach(item =>{
          
            if(item.serno==data.from){
               item.state="offline";
            }
        
           }); 
           localStorage.setItem('datas',JSON.stringify(objectdatas));
        }

 
      },
     handlePublishMsg(data) {
         console.log("goble handlePublishMsg   "+data.message);
      },
      Subscribe() {
        console.log("Subscribe() -------->>>")
        var subscribes = ''
        var datas = localStorage.getItem('datas')
        if(datas!=null && datas!= undefined && datas.length>0){
          var objectdatas = JSON.parse(datas);

          objectdatas.forEach(item =>{
          
            if(subscribes ==''){
                subscribes = item.serno;
              }else{
                  subscribes+=",";
                  subscribes+=item.serno;
             } 
        
           }); 

           this.sendToServer({
            "eventName": "__subscribe",
            "data": {
              "sessionId": this.getHomeId(),
              "sessionType": "IE",
              "messageId": this.newGuid(),
              "from": this.getMeId(),
              "to": subscribes
              }
              });

           
        }


      },

    AddModeMap: function (key,mapValue) {
       // console.log("AddModeMap ",key)
        //console.log("AddModeMap ",mapValue)
        if(this.modeMap ==null){
          var dateMap = new Map();
          //console.log("AddModeMap ",dateMap)
          dateMap.set(key,mapValue);
          this.modeMap = dateMap;
        }else{
  
           // console.log("AddModeMap----------------- ",this.modeMap.size)
            this.modeMap.set(key,mapValue);
        }
   
        
       // this.modeMap.set(key,mapValue);
             //   Vue.set(this.modeMap,key,mapValue);            
              },
      DelModeMap: function (key) {
                
              if(this.modeMap !=null){
                //console.log("DelModeMap----------------- ",this.modeMap.size)
              this.modeMap.delete(key);
              }
             // console.log("DelModeMap ",key);
  
              
     },
     newGuid() {
        let s4 = function() {
            return (65536 * (1 + Math.random()) | 0).toString(16).substring(1);
        }
        return (s4() + s4() + "-" + s4() + "-4" + s4().substr(0, 3) + "-" + s4() + "-" + s4() + s4() + s4()).toUpperCase();
    },
}